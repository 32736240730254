
.hex-box {
  position: relative;
  min-width: 60px;
  &::before,
  &::after {
    content: " ";
    position: absolute;
    display: block;
    top: 0;
    width: 40px;
    height: 100%;
    background-repeat: no-repeat;
    background-size: auto 100%;
    z-index: -1;
  }

  &::before {
    left: -20px;
    background-image: url(../../../public/assets/hex-left.svg);
  }

  &::after {
  left: calc(100% - 20px);
  background-image: url(../../../public/assets/hex-right.svg);
  }
}