@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply p-safe text-gray-900 antialiased select-none;

  /* PWA enhancements */
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

html {
  height: 100vh;
  -webkit-overflow-scrolling: touch;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
