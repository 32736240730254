@import '../../styles/mixins.scss';

#user-data {
  z-index: 50;

  @media screen and (max-width: 1880px) {
    flex-direction: column;
    padding-bottom: 85px;
    align-items: center;
  }

  .user-info {
    z-index: 40;
    width: 420px;

    @media screen and (max-width: 1280px) {
      max-width: 350px;
    }

    div.points {
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      background: #f3f4f6;
      border-radius: 999px;
      padding: 4px 12px;
      margin-top: 4px;

      @media screen and (max-width: 1280px) {
        padding: 4px 8px;
        margin-top: 2px;
      }

      h4 {
        font-size: 14px;
        font-style: italic;

        @media screen and (max-width: 1280px) {
        }
      }

      p {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-right: 1px solid rgba(0, 0, 0, 0.4);
        padding-right: 10px;
        margin-right: 10px;
        line-height: 1em;

        @media screen and (max-width: 1280px) {
          padding-right: 7px;
          margin-right: 7px;
        }

        &:last-child {
          margin: 0;
          padding: 0;
          border: none;
        }

        strong {
          font-size: 15px;

          @media screen and (max-width: 1280px) {
            font-size: 12px;
          }
        }

        span {
          font-size: 12px;
          margin-left: 3px;

          @media screen and (max-width: 1280px) {
            font-size: 11px;
            margin-left: 2px;
          }
        }
      }
    }
  }

  .user-advantages {
    display: flex;
    align-items: flex-start;

    button {
      background: none;
      border: none;

      span {
        width: 89px;
        height: 81px;
        background: url(../../assets/img/hex-advantages.svg) no-repeat center
          center;
        background-size: contain;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 16px;

        @media screen and (max-width: 1280px) {
          width: 75px;
          height: 68px;
          padding: 0 13px;
        }

        img {
          width: 100%;
        }
      }

      strong {
        font-weight: 700;
        font-size: 13px;
        line-height: 1em;
        color: #ffffff;
        display: block;
        margin-top: 14px;

        @media screen and (max-width: 1280px) {
          font-size: 11px;
        }
      }
    }
  }
}

#modal {
  @media only screen and (min-width: 831px) {
    ::-webkit-scrollbar {
      width: 5px;
      background: rgba(255, 255, 255, 0.2);

      &-thumb {
        background: rgba(255, 255, 255, 0.9);
        border-radius: 5px;
      }
    }
  }

  &.leaderboards,
  &.challenges,
  &.rewards,
  &.notifications {
    div.content {
      width: 100%;
      max-width: 940px;
      height: 661px;
      max-height: 80vh;
      background: #296abd;

      div.title {
        background: url(../../assets/img/bg-title-advantages-modal.svg)
          no-repeat center center;
        width: 515px;
        height: 93px;
        left: 50%;
        transform: translateX(-50%);
        top: -44px;
        padding-bottom: 4px;

        .ico {
          max-width: 56px;
          width: 100%;
          height: auto;
          left: 33px;
          top: calc(50% - 4px);
          transform: translateY(-50%);
        }

        h3 {
          font-style: normal;
          font-weight: 700;
          font-size: 28px;
          line-height: 1em;
          color: #000000;
        }
      }

      .bt-close {
        top: 25px;
        right: 27px;
        background: none;
        border: none;
        color: #fff;
        font-size: 42px;
      }
    }
  }

  &.challenges {
    .available-challenges,
    .your-achievements {
      display: flex;
      flex-direction: column;

      > h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        color: #ffffff;
        display: block;
        margin: 0 0 6px;
      }

      div.list {
        background: #ffffff;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        height: calc(100% - 29px);
        overflow-x: hidden;
        overflow-y: auto;
      }
    }

    .your-achievements {
      .list {
        ul {
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap;

          li {
            width: 33.33%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            img {
              display: block;
              max-width: 45px;
              margin: 0 0 5px;
            }

            p {
              font-style: normal;
              font-size: 13px;
              line-height: 15px;
              text-align: center;
              color: #333333;
            }
          }
        }
      }
    }
  }

  &.rewards {
    div.title {
      div.points {
        p {
          &:last-child {
            margin-bottom: 0;
          }

          strong {
            color: #296abd;
          }

          .img {
            svg,
            img {
              object-fit: contain;
            }
          }
        }
      }
    }

    div.item-detail {
      .img {
        border: 3px solid #ffffff;
        filter: drop-shadow(0px 2px 0px rgba(0, 0, 0, 0.1));
        width: 100%;
        height: 210px;
        margin: -56px 0 22px;
      }

      .metadata {
        h4 {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 25px;
          text-align: center;
          color: #33487a;
        }

        > p {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          text-align: center;
          color: #000000;
          margin: 0 0 18px;
        }

        .amount {
          background: #f4f4f4;

          p {
            font-family: 'Open Sans';
            padding: 0;
            width: auto;
            color: #7b61ff;

            strong {
              font-family: inherit;
              text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.25);
              font-size: 20px;
            }

            img,
            svg {
              height: auto;
              width: 32px;
            }
          }
        }
      }
    }

    div.list {
      ul {
        li {
          width: 156px;
          box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.1);

          .img {
            border: 3px solid #ffffff;
            filter: drop-shadow(0px 2px 0px rgba(0, 0, 0, 0.1));
            width: calc(100% - 14px);
            height: 91px;
            margin: -7px 7px 7px;
          }

          .metadata {
            h4 {
              font-family: 'Open Sans';
            }

            .amount {
              p {
                font-family: 'Open Sans';
                background: #f4f4f4;
                color: #7b61ff;
                margin-bottom: 4px;

                &:last-child {
                  margin-bottom: 0;
                }

                strong {
                  font-family: inherit;
                  text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.25);
                }

                img,
                svg {
                  height: auto;
                  width: 14px;
                }
              }
            }
          }
        }
      }
    }
  }

  &.leaderboards {
    div.boards {
      div.head {
        div.board-title {
          h4 {
            text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
          }
        }
      }

      div.list {
        overflow-x: hidden;
        overflow-y: auto;

        .my-position {
          .position,
          .rate {
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            text-align: center;

            span {
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              text-align: center;
              display: block;
              text-transform: uppercase;
              margin-bottom: 3px;
            }
          }

          .rate {
            span {
              text-transform: none;
            }
          }

          .user {
            p {
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              text-align: center;
            }
          }
        }

        ul {
          li {
            border-bottom: 1px dashed #888888;
            padding-bottom: 8px;
            margin-bottom: 8px;

            &:last-child {
              margin-bottom: 0;
              padding-bottom: 0;
              border: none;
            }

            > div {
              &.active {
                background: #7b61ff;
              }
            }

            .position {
              font-weight: 700;
              font-size: 16px;
              line-height: 20px;
              text-align: center;
              margin-right: 22px;

              .up,
              .down {
                display: block;
              }

              .up {
                color: #3dd208;
              }

              .down {
                color: #c92703;
              }

              span {
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                text-align: center;
                display: block;
                text-transform: uppercase;
                margin-bottom: 3px;
              }
            }

            .rate {
              font-weight: 700;
              font-size: 14px;
              line-height: 18px;
              display: flex;
              align-items: center;
              text-align: right;

              span {
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                text-align: center;
                display: block;
                margin-bottom: 3px;
              }
            }

            .user {
              p {
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
              }
            }
          }
        }

        &.has-position {
          ul {
            li {
              .position,
              .rate {
                span {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  &.notifications {
    div.list {
      overflow-x: hidden;
      overflow-y: auto;

      ul {
        li {
          margin-bottom: 22px;
          font-weight: 500;
          font-size: 16px;
          line-height: 18px;

          svg {
            width: 22px;
            height: auto;
            margin-right: 8px;
          }

          .positive {
            color: green;
          }

          .negative {
            color: orange;
          }

          .neutral {
            color: blueviolet;
          }
        }
      }
    }
  }
}

#rolling-stats {
  max-width: 440px;
  @media screen and (max-width: 1280px) {
    max-width: 370px;
  }
}

#closing-offer {
  position: relative;
  width: 34vw;
  max-width: 600px;

  .hex {
    max-width: 170px;

    h1 {
      margin-top: -15px;
    }
  }
}

#daily-order {
  .swiper-slide {
    width: 12.7vw;
    min-width: 210px;
    max-width: 243px;
    padding-bottom: 50px;
  }

  .swiper-pagination-bullet {
    background: white;
  }
}

#optional-services {
  max-width: 42vw;
  position: relative;

  .hex {
    h1 {
      margin-top: -15px;
    }

    @include HexGrid(120px);

    @media screen and (min-width: 1600px) {
      @include HexGrid(140px);
    }

    @media screen and (min-width: 1920px) {
      @include HexGrid(160px);
    }
  }
}

#dashboard-mx {
  #closing-offer,
  #optional-services {
    .hex {
      h1 {
        margin-top: -15px;
      }

      @include HexGrid(160px);

      @media screen and (min-width: 1600px) {
        @include HexGrid(200px);
      }

      @media screen and (min-width: 1920px) {
        @include HexGrid(220px);
      }
    }
  }

  #rolling-stats {
    // height: ;
    max-width: 400px;
  }
}
