.heading {
  &::before{
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 260px;
    height: 50px;
    background: url(../../assets/img/header-line.svg) no-repeat bottom right;
    background-size: auto 100%;
  }
  &.right{
    &::before{
      left: auto;
      right: 0;
      background: url(../../assets/img/header-line-right.svg) no-repeat left;
      background-size: auto 100%;
    }
  }
}