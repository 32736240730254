body {
  margin: 0;
  min-height: 100vh;
  background: url(./assets/img/bg.jpg) no-repeat center;
  background-size: cover;
  background-attachment: fixed;
}

#root {
  max-width: 1920px;
  margin: 0 auto;
}

.text-shadow {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}

#modal {
  z-index: 9999;
  transition: opacity 0.4s ease-in-out 0s;
  opacity: 1;
  animation-duration: 0.4s;
  animation-name: fadeIn;

  &.fadeOut {
    opacity: 0;
  }
}

html body .tooltip {
  padding: 5px 16px;
  font-weight: 500;

  &.show {
    opacity: 1;
  }
}

.btn-rewards {
  // min-height: 29px;
  font-family: 'Open Sans', sans-serif;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.25);

  &.green {
    background: linear-gradient(180deg, #a9f04e 0%, #18ca54 100%);
    box-shadow: 0px 3px 0px #1abb73;
  }

  &.red {
    background: linear-gradient(180deg, #f0754e 0%, #ca2218 100%);
    box-shadow: 0px 3px 0px #9e241a;
  }
}
