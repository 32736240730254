
@mixin HexGrid($size:160px) {
  width: $size;
  min-width: $size;

  &:nth-child(3n-1) {
    transform: translate3d(0%, -50%, 0);
    margin-left:-$size/4;
  }

  &:nth-child(3n) {
    transform: translate3d(0%, 50%, 0);
    margin-left:-$size;
  }

  &:nth-child(3n+1) {
    margin-left:-$size/4;
  }
}